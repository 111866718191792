@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-dots {
    bottom: 5vh!important;
}

.slick-dots .slick-active button:before {
    color: #fff!important;
    font-size: 16px!important;
}
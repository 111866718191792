:root {
  --white: #FFFFFF;
  --black: #231F20;
  --aqua: #05BFE0;
  --tangerine: #FF610F;
  --violet: #553192;
  --grey: #ABABAB;
  --lightgrey: #F5F5F5;

  --transition: all 150ms ease-in-out;
}

.transition-one {
  transition: var(--transition);
}


html {
  scroll-behavior: smooth;
}

.noScrollBar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.noScrollBar::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

@font-face {
  font-family: 'Agrandir';
  src: url('./assets/fonts/Agrandir-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Pressura';
  src: url('./assets/fonts/GT-Pressura-Regular-Trial.woff2') format('truetype');
}

.font-agrandir {
  font-family: 'Agrandir';
}

.font-pressura {
  font-family: 'Pressura';
}

.buttonwhite {
  border-radius: 5px;
  font-family: 'Agrandir';
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 10px;
  background-color: var(--white);
  color: var(--violet);
  width: fit-content;
  cursor: pointer;
}

.buttonwhite:active {
  background-color: var(--violet);
  color: var(--white);
  border: 1px solid var(--white);

}

.buttonblack {
  border-radius: 5px;
  font-family: 'Agrandir';
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 10px;
  background-color: var(--black);
  color: var(--white);
  width: fit-content;
  cursor: pointer;
}

.buttonblack:active {
  background-color: var(--white);
  color: var(--black);
  border: 1px solid var(--black);

}

.buttonviolet {
  border-radius: 5px;
  font-family: 'Agrandir';
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 10px;
  background-color: var(--violet);
  color: var(--white);
  width: fit-content;
  cursor: pointer;
}

.buttonviolet:active {
  background-color: var(--white);
  color: var(--violet);
  border: 1px solid var(--violet);
}

/**/

.heading > div {
  overflow: hidden;
}
.heading > div > p {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  white-space: nowrap;
  margin: 0;
  line-height: 117%;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
}

div.heading > div {
  -webkit-animation-delay: 0;
          animation-delay: 0;
}
div.heading > div:nth-child(2) > p {
  -webkit-animation-delay: 0.05s;
          animation-delay: 0.05s;
}

div.heading > div {
  -webkit-animation-delay: 0;
          animation-delay: 0;
}
div.heading > div:nth-child(3) > p {
  -webkit-animation-delay: 0.075s;
          animation-delay: 0.075s;
}

.slide-up {
  -webkit-animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
          animation: slide-up 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
}

@-webkit-keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}

/**/

.slide-left {
  -webkit-animation: slide-left 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
          animation: slide-left 0.75s cubic-bezier(0.65, 0, 0.35, 1) both;
}

@-webkit-keyframes slide-left {
  0% {
    transform: translateX(1000px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(1000px);
  }
  100% {
    transform: translateX(0);
  }
}

/* Sponsors */
.sponsors {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.sponsors-list {
  width: 100%;
  padding: 0 60px;
  display: flex;
  flex-direction: row;
  /*align-items: center;*/
  margin: 40px 0 10px 0;
}

.sponsors-list-item {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 0 40px 0 0;
  position: relative;
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.sponsors-list-item.inovact a {
  width: 110px;
}

.sponsors-list-item:last-child {
  margin: 0;
}

.sponsors-list-item .badge {
  position: absolute;
  top: 5px;
  right: 5px;
  display: block;
}

.sponsors-list-item .badge img {
  width: 40px;
  height: auto;
}

.sponsors-list-item a {
  max-width: 150px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.sponsors-list-item a img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

@media only screen and (max-width: 768px) {
  .sponsors-list {
    flex-direction: column;
  }
  .sponsors-list-item {
    margin: 0 0 30px 0;
  }
  .sponsors-list-item:last-child {
    margin: 0;
  }
}
.eventscroll {
    scrollbar-color: var(--violet);
    transition: var(--transition);
}

/* Styles de la scrollbar */
.eventscroll::-webkit-scrollbar {
  width: 8px!important; /* Largeur de la scrollbar */
}

/* Styles de la poignée de la scrollbar */
.eventscroll::-webkit-scrollbar-thumb {
  background-color: var(--violet)!important; /* Couleur de la poignée */
  border-radius: 100px; /* Coins arrondis de la poignée */
}
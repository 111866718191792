

#newArticle header {
    height: 50px;
    position: unset;
    box-shadow: 0px 15px 10px -5px rgb(158 158 158 / 30%);
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--white);
    margin: 0;
}

#newArticle header .col-md-8, #newArticle header .col-md-1 {
    align-items: center;
    display: flex;
    justify-content: center;
}

#newArticle header svg {
    font-size: 35px;
    cursor: pointer;
    color: var(--orange);
}

#newArticle form .form-ctrl {
    display: grid;
    margin-bottom: 20px;
}

#newArticle form .btn-ctrl {
    margin-top: 60px;
    float: right;
}

#newArticle form input {
    width: 100%;
}

#newArticle form select{
    height: 50px;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    border: 1px solid rgba(var(--b6a, 219, 219, 219), 1);
    padding-left: 5px;
  }

#newArticle form .quill {
    min-height: 300px;
}

.ql-toolbar.ql-snow button {
    background-color: transparent!important;
}

#newArticle form .post-btn {
    background-color: var(--orange)!important;
    color: var(--white);
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    padding: 0.7rem 1.5rem;
    cursor: pointer;
    margin-bottom: 50px;
}

#newArticle form .post-btn:hover {
    background-color: var(--white)!important;
    color: var(--orange);
    border: 1px solid var(--orange);
}
.volunteers:hover {
    overflow-y: scroll;
}

.volunteers {
    scrollbar-color: var(--tangerine);
    transition: var(--transition);
}

/* Styles de la scrollbar */
.volunteers::-webkit-scrollbar {
  height: 8px!important; /* Largeur de la scrollbar */
}

/* Styles de la poignée de la scrollbar */
.volunteers::-webkit-scrollbar-thumb {
  background-color: var(--tangerine)!important; /* Couleur de la poignée */
  border-radius: 100px; /* Coins arrondis de la poignée */
}